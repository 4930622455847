import { TitleSection } from 'src/shared/title-section/Title-section';
import services from '../../assets/services.png';
import './offerYou.scss';

const OfferYou = () => {
  return (
    <div className="pb-6 flex flex-col offerYou justify-center items-center max-md:pt-6 max-md:pb-8">
      <TitleSection title="Te garantizamos" description=""></TitleSection>

      <div className="w-1/2 max-md:w-full pb-6 pt-8">
        <img src={services} alt="Te garantizamos" />
      </div>
    </div>
  );
};

export default OfferYou;
