import './home-button.scss';
import '../../shared/buttonHome/ButtonHome.scss';

export interface HomeButtonProps {
  color: string;
  label: string;
  url: string;
}

const HomeButton: React.FC<HomeButtonProps> = ({ color, label, url }) => {
  const routes: Record<string, number> = {
    'our-services': 1,
    'contact-us': 2,
  };

  const click = (): void => {
    const header: HTMLElement = document.querySelector('.header-support');

    if (url === 'contact-us') {
      const contactUs: HTMLElement = document.querySelector('#contact-us');
      window.scrollTo({
        top: contactUs.offsetTop - header.offsetHeight,
        behavior: 'smooth',
      });
      return;
    }
    const top: number =
      routes[url] * (window.innerHeight - header.offsetHeight);
    window.scrollTo({ top, behavior: 'smooth' });
  };

  return (
    <button onClick={click} className={`btnHome home-button__${color}`}>
      {label}
    </button>
  );
};

export default HomeButton;
