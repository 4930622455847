import './outsourcing-sst.scss';
import { Banner } from '../../shared/banner/Banner';
import imageLogo from 'src/assets/images/logo-outsourcing-sst.png';
import { TitleSection } from '../../shared/title-section/Title-section';
import lifeCycle from 'src/assets/images/ciclodevida.png';
import { banner, InfoListItem, items } from './outsourcing-sst-data';
import { FaCircle } from 'react-icons/fa6';
import ReactPlayer from 'react-player';
import outsourcing from 'src/assets/outsourcing.mp4';
import { ContactModal } from '../../../src/shared/contact-modal/Contact-Modal';
import { useState } from 'react';

export const OutsourcingSst = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [planSelected, setPlanSelected] = useState('');

  const closeModal = () => setIsModalVisible(false);

  const subscriptionClick = (plan: string) => {
    setPlanSelected(plan);
    setIsModalVisible(true);
  };

  return (
    <div className="outsourcing-sst">
      <Banner
        description={banner.description}
        image={banner.image}
        logo={banner.logo}
        title={banner.title}
      ></Banner>

      <div className="outsourcing-sst__content">
        <div className="request">
          <img className="request__logo" src={imageLogo} alt="logo"></img>
          <TitleSection
            title="Solicita"
            description="Tu profesional experto en SST."
          ></TitleSection>
          <img
            src={lifeCycle}
            alt="ciclo-de-vida"
            className="request__life-cycle"
          />

          {/* <div className="request__info-list">
            {items.map((item: InfoListItem, index: number) => (
              <div className="request__info-list__item" key={index}>
                <span
                  className="request__info-list__item__number"
                  style={{
                    background: `linear-gradient(90deg, ${item.primaryColor}, ${item.secondaryColor})`,
                  }}
                >
                  {item.number}
                </span>
                <div className="request__info-list__item__texts">
                  {item.texts.map((text: string, index: number) => (
                    <div
                      className="request__info-list__item__texts__text"
                      key={index}
                      style={{
                        color: item.secondaryColor,
                      }}
                    >
                      <FaCircle />
                      <span className="request__info-list__item__texts__text__label">
                        {text}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div> */}
        </div>

        <div className="pt-6">
            <ReactPlayer
              url={outsourcing}
              controls
              loop
              width="25%"
              height="25%"
              style={{ margin: '0 auto' }}
            ></ReactPlayer>
          </div>
        <div className="subscription">
          <TitleSection
            title="Suscripción Outsourcing SST"
            description="Profesionales SST. Aplican condiciones y restricciones"
          ></TitleSection>

          <table className="plan-table">
            <thead className="plan-table__header">
              <tr className="plan-table__header__row">
                <th className="plan-table__header__row__title">Planes</th>
                <th className="plan-table__header__row__cell">Por Mes</th>
                <th className="plan-table__header__row__cell">Por Dia</th>
                <th className="plan-table__header__row__cell">Por Hora</th>
              </tr>
            </thead>
            <tbody className="plan-table__body">
              <tr className="plan-table__body__row">
                <td className="plan-table__body__row__title">Desde</td>
                <td className="plan-table__body__row__cell">
                  $ 2.332.850{' '}
                  <span className="plan-table__body__row__cell__light">
                    + Iva
                  </span>
                </td>
                <td className="plan-table__body__row__cell">
                  $ 274.320{' '}
                  <span className="plan-table__body__row__cell__light">
                    + Iva
                  </span>
                </td>
                <td className="plan-table__body__row__cell">
                  $ 49.450{' '}
                  <span className="plan-table__body__row__cell__light">
                    + Iva
                  </span>
                </td>
              </tr>
              <tr className="plan-table__body__row">
                <td className="plan-table__body__row__title">Ahorro</td>
                <td className="plan-table__body__row__cell">70.48%</td>
                <td className="plan-table__body__row__cell">30.65%</td>
                <td className="plan-table__body__row__cell">No aplica</td>
              </tr>
              <tr className="plan-table__body__row">
                <td className="plan-table__body__row__title">¡Lo quiero!</td>
                <td className="plan-table__body__row__cell">
                  <button
                    className="subscription__order"
                    onClick={() => {
                      subscriptionClick('Mes');
                    }}
                  >
                    Obtener
                  </button>
                </td>
                <td className="plan-table__body__row__cell">
                  <button
                    className="subscription__order"
                    onClick={() => {
                      subscriptionClick('Dia');
                    }}
                  >
                    Obtener
                  </button>
                </td>
                <td className="plan-table__body__row__cell">
                  <button
                    className="subscription__order"
                    onClick={() => {
                      subscriptionClick('Hora');
                    }}
                  >
                    Obtener
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ContactModal
        isVisible={isModalVisible}
        onClose={closeModal}
        option={'outsourcing'}
        plan={planSelected ?? ''}
      ></ContactModal>
    </div>
  );
};
