import './header-mobile.scss';
import { FaBars } from 'react-icons/fa';
import { FaBriefcase } from 'react-icons/fa';
import { FaHome } from 'react-icons/fa';
import { FaNewspaper } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';
import { HeaderMobileDropdownButton } from '../header-mobile-dropdown-button/Header-mobile-dropdown-button';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';

export interface HeaderMobileButton {
  label: string;
  route?: string;
  icon: JSX.Element;
  tabIndex?: number;
  options?: HeaderMobileButtonOption[];
  closeMenuMobile?: () => void;
}

interface HeaderMobileButtonOption {
  label: string;
  route: string;
}

export const HeaderMobile = () => {
  const [isActive, setIsActive] = useState(false);

  const buttons: HeaderMobileButton[] = [
    {
      label: 'Inicio',
      route: '/',
      icon: <FaHome></FaHome>,
    },
    {
      label: 'Quiénes Somos',
      route: '/quienes-somos',
      icon: <FaNewspaper></FaNewspaper>,
    },
    {
      label: 'Línea de Servicios',
      icon: <FaBriefcase></FaBriefcase>,
      options: [
        { label: 'Ser Pyme SST', route: '/ser-pyme-sst' },
        { label: 'Outsourcing SST', route: '/outsourcing-sst' },
        { label: 'Proveedores SST', route: '/proveedores-sst' },
        // { label: 'Convenciones SST', route: '/convenciones-sst' },
      ],
    },
  ];

  const closeMenuMobile = (): void => {
    setIsActive(false);
  };

  return (
    <div
      className={`header-mobile ${isActive ? 'header-mobile__expanded' : ''}`}
    >
      <div className="header-mobile__content">
        <button
          onClick={() => setIsActive(!isActive)}
          className="header-mobile__content__button"
        >
          <FaBars className="header-mobile__content__button__icon"></FaBars>
          <span className="header-mobile__content__button__label">Menú</span>
        </button>
        <Link to="/" className="header-mobile__content__logo">
          <div className="header-mobile__content__logo__image">
            <img
              className="header-mobile__content__image__img"
              src="https://aserseguridad.co/assets/images/nav/logo-aser.png"
              alt="Logo"
            />
          </div>
        </Link>
        <div className="header-mobile__content__nothing"></div>
      </div>

      <div className="header-mobile__options">
        {buttons.map((el: HeaderMobileButton, index) => (
          <React.Fragment key={index}>
            <HeaderMobileDropdownButton
              icon={el.icon}
              label={el.label}
              options={el.options}
              route={el.route}
              closeMenuMobile={closeMenuMobile}
              tabIndex={isActive ? 0 : -1}
            />
            {index !== buttons.length - 1 && (
              <div className="header-mobile__options__divider"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
