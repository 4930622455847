import './ser-pyme-sst.scss';
import { Banner } from '../../shared/banner/Banner';
import { TitleSection } from '../../shared/title-section/Title-section';
import {
  banner,
  items,
  planCumpliance,
  plans,
  protections,
  SerPymeSstItem,
  SerPymeSstPlan,
  SerPymeSstProtection,
} from './ser-pyme-sst-data';
import { useState } from 'react';
import { planIcons } from './ser-pyme-sst-data';
import imageLogo from 'src/assets/images/logo-pyme-sst.png';
import imageLogoSura from 'src/assets/images/logo-aSER-Sura-horizontal.jpg';
import { Button } from 'src/shared/button/Button';
import ReactPlayer from 'react-player';
import serPyme from 'src/assets/serpymes.mp4';
import { ContactModal } from '../../shared/contact-modal/Contact-Modal';

export const SerPymeSst = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [planSelected, setPlanSelected] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const closeModal = () => setIsModalVisible(false);

  const getActualTab = (): SerPymeSstPlan => {
    return plans[tabIndex];
  };

  const capitalize = (text: string): string => {
    return text.toLowerCase().replace(/\b(\s\w|^\w)/g, function (txt) {
      return txt.toUpperCase();
    });
  };

  const openPDF = (price: string) => {
    window.open(
      `/assets/pdf-plans/Planes-ser-pymes-${getActualTab().type}-${price === 'basic' ? 'basico' : price}.pdf`,
      '_blank'
    );
  };

  const subscriptionClick = (plan: string) => {
    setPlanSelected(plan);
    setIsModalVisible(true);
  };

  return (
    <div className="ser-pyme-sst">
      <Banner
        description={banner.description}
        image={banner.image}
        logo={banner.logo}
        title={banner.title}
      ></Banner>

      <div className="ser-pyme-sst__content">
        <div className="starter-pack">
          <img src={imageLogo} alt="logo" className="starter-pack__logo" />
          <TitleSection
            title="Paquete inicial SST"
            description="Incluido en los diferentes planes."
          ></TitleSection>
          <div className="starter-pack__items">
            {items.map((el: SerPymeSstItem, index) => (
              <div className="starter-pack__items__item" key={index}>
                <div className="starter-pack__items__item__icon">{el.icon}</div>
                <div className="starter-pack__items__item__content">
                  <span className="starter-pack__items__item__content__title">
                    {el.title}
                  </span>
                  <span className="starter-pack__items__item__content__description">
                    {el.description}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="pt-6">
          <ReactPlayer
            url={serPyme}
            controls
            loop
            width="70%"
            height="70%"
            style={{ margin: '0 auto' }}
          ></ReactPlayer>
        </div>

        <div className="subscription">
          <TitleSection
            title="Suscripción SST - Ser Pyme SST"
            description="Porcentajes de cumplimiento Decreto 1072 de 2015 y resolución 0312 de 2019 según plan seleccionado."
          ></TitleSection>

          <div className="subscription__tabs">
            <span className="subscription__tabs__label">
              ¿Cuántas personas tiene tu empresa?
            </span>
            <div className="subscription__tabs__container">
              {plans.map((el: SerPymeSstPlan, index: number) => (
                <button
                  className={`subscription__tabs__container__tab ${index === tabIndex ? 'subscription__tabs__container__tab__active' : ''}`}
                  key={index}
                  onClick={() => setTabIndex(index)}
                  tabIndex={0}
                >
                  <span className="subscription__tabs__container__tab__label">
                    {el.quantity}
                  </span>
                </button>
              ))}
            </div>
          </div>

          <div className="subscription__container">
            <span className="subscription__container__label">Planes</span>
            <div className="subscription__container__plans">
              {Object.keys(getActualTab().prices).map(
                (price: any, index: number) => (
                  <div
                    className={`subscription__container__plans__plan subscription__container__plans__plan__${price}`}
                    key={index}
                  >
                    <div className="subscription__container__plans__plan__title">
                      <div
                        className={`subscription__container__plans__plan__title__icon`}
                      >
                        {planIcons[price]}
                      </div>
                      <span className="subscription__container__plans__plan__title__label">
                        {capitalize(price)}
                      </span>
                    </div>

                    <div className="subscription__container__plans__plan__content">
                      <span className="subscription__container__plans__plan__content__price">
                        ${' '}
                        {Number(
                          getActualTab().prices[price] || 0
                        ).toLocaleString('es-ES')}
                        {' COP '}
                        <span className="subscription__container__plans__plan__content__price__iva">
                          {' '}
                          + IVA
                        </span>
                      </span>
                      <span className="subscription__container__plans__plan__content__aditional">
                        Mensual por un año. Aplican condiciones.
                      </span>
                      <button
                        className="subscription__container__plans__plan__content__order"
                        onClick={() => {
                          subscriptionClick(price);
                        }}
                      >
                        Obtener {price}
                      </button>
                      <button
                        className="subscription__container__plans__plan__content__more-information"
                        onClick={() => openPDF(price)}
                      >
                        Más información
                      </button>
                      <span className="subscription__container__plans__plan__content__compliance">
                        {planCumpliance[price]}
                      </span>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <div className="appWeb">
          <img
            className="w-1/4"
            src="https://aserseguridad.co/assets/images/pymes/img-app.svg"
            alt="test app web"
          />
          <div className="w-1/2 flex flex-col justify-center">
            <h1>Aplicativo Web</h1>
            <p>Para la Implementación, Gestión y Administración del SG-SST</p>
            <div>
              <Button variant="secondary">Prueba demo sin costo</Button>
            </div>
          </div>
        </div>

        <div className="support">
          <div className="support__image">
            <img
              src={imageLogoSura}
              alt="logo-with-sura"
              className="support__image__img"
            />
          </div>
          <span className="support__label">
            <strong>aSERseguridad</strong> con el respaldo de seguros{' '}
            <strong>SURA, </strong> garantiza su compromiso con tu empresa
            asegurando la calidad y cumplimiento de sus servicios con los
            siguientes amparos incluidos en cualquier plan de suscripción
            seleccionado:
          </span>

          <div className="support__protections">
            {protections.map((el: SerPymeSstProtection, index: number) => (
              <div className="support__protections__protection" key={index}>
                <div className="support__protections__protection__title">
                  <div className="support__protections__protection__title__icon">
                    {el.icon}
                  </div>
                  <span className="support__protections__protection__title__text">
                    {el.title}
                  </span>
                </div>
                <div className="support__protections__protection__wave">
                  <svg
                    className="support__protections__protection__wave__gray"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                    preserveAspectRatio="none"
                  >
                    <path
                      fill="#d3d3d3"
                      fillOpacity="1"
                      d="M0,64L48,90.7C96,117,192,171,288,165.3C384,160,480,96,576,106.7C672,117,768,203,864,229.3C960,256,1056,224,1152,192C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                    ></path>
                  </svg>
                  <svg
                    className="support__protections__protection__wave__orange"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                    preserveAspectRatio="none"
                  >
                    <path
                      fill="#f59454"
                      fillOpacity="1"
                      d="M0,160L48,170.7C96,181,192,203,288,213.3C384,224,480,224,576,202.7C672,181,768,139,864,122.7C960,107,1056,117,1152,133.3C1248,149,1344,171,1392,181.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                    ></path>
                  </svg>
                </div>

                <span className="support__protections__protection__description">
                  {el.description}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ContactModal
        isVisible={isModalVisible}
        onClose={closeModal}
        option={'serpymesst'}
        plan={planSelected ?? ''}
        type={getActualTab().type ?? ''}
      ></ContactModal>
    </div>
  );
};
